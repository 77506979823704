import React from 'react'
import {Link} from 'gatsby'
import Logo from '../images/site-logo.png'
import Container from "../components/container"
import Row from "../components/row"
import Col from "../components/column"
import { FaFacebookF, FaTwitter,FaYoutube,FaLinkedinIn} from 'react-icons/fa'
import GdprReadBadge from '../images/gdpr_ready_badge.png'

export default function Footer() {
    return(
        <div className="footer-container">
            <Container>
                <Row>
                    <Col size="5">
                        <Link className="footer-logo mb-3" to="/">
                            <img src={Logo} alt="Optinly" />
                        </Link>
                        <Row>
                            <Col size="9">
                                <p>Grow your email list and increase your revenue by creating popups people love to see. Optinly is built with one purpose - to help online businesses  & eCommerce stores thrive. It’s super easy to use and works always!</p>
                            </Col>
                        </Row>
                    </Col>
                    <Col size="2">
                        <h4 className="footer-title">Quick Links</h4>
                        <ul>
                            <li>
                                <Link to="/features/">Features</Link>
                            </li>
                            <li>
                                <Link to="/pricing/">Pricing</Link>
                            </li>
                            <li>
                                <Link to="/blog/">Blog</Link>
                            </li>
                            <li>
                                <a href="https://help.optinly.com/" target="_blank" rel="noopener">Docs</a> 
                            </li>
                            <li>
                                <Link to="/support/">Support</Link>
                            </li>
                            <li>
                                <Link to="/about-us/">About Us</Link>
                            </li>
                            <li>
                                <Link to="/partner-program/">Partner Program</Link>
                            </li>
                        </ul>
                    </Col>
                    <Col size="2" className="d-flex align-items-center">
                        <img src={GdprReadBadge}  alt="" className="mx-auto my-5 d-block img-responsive footer-gdpr"/>
                    </Col>        

                    {/** Social */}

                    <Col size="3">
                        <div className="footer-share-links text-center w-100">
                            <h4 className="footer-title mb-4">Connect with us</h4>
                            <ul className="social mb-4">
                                <li>
                                    <a href="https://twitter.com/getoptinly" target="_blank">
                                        <FaTwitter size="20" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.facebook.com/getoptinly/" target="_blank">
                                        <FaFacebookF size="20" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.youtube.com/channel/UCB9w46zJ7-o79ektk9MX-pQ" target="_blank">
                                        <FaYoutube size="20" />
                                    </a>
                                </li>
                                <li>
                                    <a href="https://www.linkedin.com/company/optinly/" target="_blank">
                                        <FaLinkedinIn size="20" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                        <div className="mt-3">
                            <div className="footer-partner-program text-center">
                                <strong>Earn Upto 30 %</strong><br />
                                Join Our Partner Program
                                <Link to="/partner-program" className="hidden-link"></Link>
                            </div>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <div className="footer-sm">
                    <Row>
                        <Col size="6">
                            <p>&copy; <Link to="/">Optinly</Link> <script>document.write(new Date().getFullYear());</script>All Rights Reserved</p>
                        </Col>
                        <Col size="6" className="text-right">
                            <ul>
                                <li>
                                    <Link to="/privacy-policy/">Privacy policy</Link>
                                </li>
                                <li>
                                    <Link to="/terms-of-service/">Terms of Service</Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>
                </div>
            </Container>
        </div>
    )
}
