const Menubar = [
   // {
   //    TITLE: 'Features',
   //    LINK: '/features/',
   // },
   {
      TITLE: 'Features',
      LINK: '/features/',
      ISMEGAMENU: true,
      DROPDOWN_TARGET: 'featuresDropdown',
      DROPDOWN_LINKS: [
         // {
         //    TITLE: 'Integrations',
         //    LINK: '/integrations/',
         // },
         {
            MENUTITLE: 'Solutions',
            SUB_DROPDOWN_MENU: true,
            SUB_DROPDOWN_MENU_LINKS: [
               {                
                  TITLE: 'Grow Your Email List',
                  LINK: '/features/grow-your-email-list', 
               },           
               {
                  TITLE: 'Increase Sales Conversion',
                  LINK: '/features/increase-ecommerce-sales',
               },
            ]
         },
         {
            MENUTITLE: 'Features',
            SUB_DROPDOWN_MENU: true,
            SUB_DROPDOWN_MENU_LINKS: [
               {
                  TITLE: 'Exit Intent Popup',
                  LINK: '/features/exit-intent',
               },
               {
                  TITLE: 'Email Popups',
                  LINK: '/features/email-popups',
               },
               {
                  TITLE: 'Spin The Wheel Popups',
                  LINK: '/features/spin-the-wheel-pop-ups',
               },
               {
                  TITLE: 'Multiple Triggers Popup',
                  LINK: '/features/multiple-popup-triggering',
               },
            ]
         },
      ],
   },
   {
      TITLE: 'Pricing',
      LINK: '/pricing/',
   },
   {
      TITLE: 'Blog',
      LINK: '/blog/',
   },
   {
      TITLE: 'Docs',
      LINK: 'https://help.optinly.com/',
      TARGET:'_blank'
   },
   {
      TITLE: 'Support',
      LINK: '/support/',
   },
   // {
   //    TITLE: 'Use cases',
   //    LINK: '/#use-cases',
   // },
   // {
   //    TITLE: 'Resources',
   //    LINK: '/resources/',
      
   //    DROPDOWN_TARGET: 'featuresDropdown',
   //    DROPDOWN_LINKS: [
   //       {                
   //          TITLE: 'Blog',
   //          LINK: '/blog/', 
   //       },           
   //       {
   //          TITLE: 'Docs',
   //          LINK: 'https://help.optinly.com/',
   //          TARGET:'_blank'
   //       },
   //       {
   //          TITLE: 'Support',
   //          LINK: '/support/',
   //       },
   //       // {
   //       //    TITLE: 'BFCM Deal',
   //       //    LINK: '/wordpress-black-friday-cyber-monday-deals-2020',
   //       //    ICON: `https://raw.githubusercontent.com/optinly/optinly-images/master/icon.png`,
   //       // }
   //    ],
   // },
];

export default Menubar;
