import React from 'react'
import Logo from '../../images/logo1.png'

import GDPR from '../../images/gdpr_ready_badge.png'
import GozenLogo from '../../images/gozen_badge.png'
import facebook from '../../images/integration-pages/footer/facebook.png'
import twitter from '../../images/integration-pages/footer/twitter.png'
import linkedin from '../../images/integration-pages/footer/linkedin.png'
import { Link, graphql, useStaticQuery } from 'gatsby'
import FooterLink from './FooterLink'
import { navigate } from 'gatsby-link'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const IntegrationFooter = () => {

const data = useStaticQuery(graphql`

query FooterImageQuery {

    Logo:  file(absolutePath: {regex: "/images/"}, 
    name: {eq: "logo1"}) {
    childImageSharp {
      gatsbyImageData(placeholder: NONE, )
    }
  }

  GDPR:  file(absolutePath: {regex: "/images/"}, 
  name: {eq: "gdpr_ready_badge"}) {
  childImageSharp {
    gatsbyImageData(placeholder: NONE, )
  }
}

GozenLogo:  file(absolutePath: {regex: "/images/"}, 
  name: {eq: "gozen_badge"}) {
  childImageSharp {
    gatsbyImageData(placeholder: NONE, )
  }
}


facebook:  file(absolutePath: {regex: "/images/integration-pages/footer/"}, 
  name: {eq: "facebook"}) {
  childImageSharp {
    gatsbyImageData(placeholder: NONE, )
  }
}


linkedin:  file(absolutePath: {regex: "/images/integration-pages/footer/"}, 
  name: {eq: "linkedin"}) {
  childImageSharp {
    gatsbyImageData(placeholder: NONE, )
  }
}

twitter:  file(absolutePath: {regex: "/images/integration-pages/footer/"}, 
  name: {eq: "twitter"}) {
  childImageSharp {
    gatsbyImageData(placeholder: NONE, )
  }
}



}
`)


const Logo = getImage(data.Logo)
const GDPR = getImage(data.GDPR)
const GozenLogo = getImage(data.GozenLogo)
const linkedin =getImage(data.linkedin)
const twitter =getImage(data.twitter)
const facebook =getImage(data.facebook)


    return (
        <>
            <section className="w-11/12 mx-auto my-4">

                <div className="w-full px-4 pb-3" style={{borderBottom: '0.6px solid #AFAFAF'}}>
                    <Link to="/">
                        <GatsbyImage image={Logo} alt=''/>
                        {/* <img src={Logo} alt="" /> */}
                    </Link>
                </div>

                {/* TODO: remove current grid class and replace the commented one  */}
                {/* <div className="grid w-full grid-cols-1 gap-3 py-4 md:grid-cols-2 xl:grid-cols-4"> */}

                {/* REMOVE: DELETE THIS LINE AND REPLACE ABOVE LINE */}
                <div className="grid w-full grid-cols-1 gap-3 py-4 md:grid-cols-2 xl:grid-cols-4">
                    {/* integration */}
                    <div className="flex flex-col items-start space-y-2">

                        <div className="text-lg font-semibold text-button_text font-poppins">CMS{' & '}Ecommerce Integration</div>
                        
                        <div className="flex flex-row space-x-10">
                            <div className="flex flex-col items-start space-y-1">
                                <FooterLink to="/integration/wordpress/" text="WordPress Popup" />
                                <FooterLink to="/integration/shopify/" text="Shopify Popup" />
                                <FooterLink to="/integration/magento/" text="Magento Popup" />
                                <FooterLink to="/integration/webflow/" text="Webflow Popup" />
                                <FooterLink to="/integration/drupal/" text="Drupal Popup" />
                                <FooterLink to="/integration/joomla/" text="Joomla Popup" />
                            </div>

                            <div className="flex flex-col items-start space-y-1">
                                <FooterLink to="/integration/mailchimp/" text="Mailchimp Popup" />
                                <FooterLink to="/integration/mailerlite/" text="Mailerlite Popup" />
                                <FooterLink to="/integration/sendgrid/" text="SendGrid Popup" />
                                <FooterLink to="/integration/sendinblue/" text="Sendinblue Popup" />
                                <FooterLink to="/integration/sendloop/" text="Sendloop Popup" />
                                <FooterLink to="/integration/bigcommerce/" text="BigCommerce Popup" />
                            </div>
                        </div>
                    </div>

                    {/* compare */}
                    <div className="flex flex-col space-y-2">

                        <div className="text-lg font-semibold text-button_text font-poppins">Compare <span className="text-primary">Optinly</span> with</div>
                        
                        {/* Single line */}
                        {/* <div className="flex flex-col items-start space-y-1">
                            <FooterLink to="/comparison/optinmonster/" text="Optinmonster" />
                                <FooterLink to="/comparison/sleeknote/" text="Sleeknote" />
                                <FooterLink to="/comparison/optimonk/" text="Optimonk" />
                                <FooterLink to="/comparison/ninja-popup/" text="Ninja Popup" />
                                <FooterLink to="/comparison/adoric/" text="Adoric" />
                                <FooterLink to="/comparison/mailmunch/" text="Mailmunch" />
                                <FooterLink to="/comparison/poptin/" text="Poptin" />
                                <FooterLink to="/comparison/hello-bar/" text="Hello Bar" />
                                <FooterLink to="/comparison/privy/" text="Privy" />
                                <FooterLink to="/comparison/sumo/" text="Sumo" />
                        </div> */}

                        <div className="flex flex-row space-x-16">
                            <div className="flex flex-col items-start space-y-1">
                                <FooterLink to="/comparison/optinmonster/" text="Optinmonster" />
                                <FooterLink to="/comparison/sleeknote/" text="Sleeknote" />
                                <FooterLink to="/comparison/optimonk/" text="Optimonk" />
                                <FooterLink to="/comparison/ninja-popup/" text="Ninja Popup" />
                                <FooterLink to="/comparison/adoric/" text="Adoric" />
                            </div>

                            <div className="flex flex-col items-start space-y-1">
                                <FooterLink to="/comparison/mailmunch/" text="Mailmunch" />
                                <FooterLink to="/comparison/poptin/" text="Poptin" />
                                <FooterLink to="/comparison/hello-bar/" text="Hello Bar" />
                                <FooterLink to="/comparison/privy/" text="Privy" />
                                <FooterLink to="/comparison/sumo/" text="Sumo" />
                            </div>
                        </div>

                    </div>

                    {/* quicklink */}
                    <div className="flex flex-col space-y-2">

                        <div className="text-lg font-semibold text-button_text font-poppins">Quick Links</div>

                        <div className="flex flex-col items-start space-y-1">
                            <a  className="text-base font-normal text-font_secondary font-roboto" href="https://www.gozen.io/?utm_source=+optinly&utm_medium=referral+&utm_campaign=+optinly+to+gozen+&utm_id=+" target="_blank" rel="nofollow">Email Marketing Software</a> 
                            <FooterLink to="/features/" text="Features" />
                            <FooterLink to="/integration/" text="Integrations" />
                            <FooterLink to="/pricing/" text="Pricing" />
                            <FooterLink to="/blog/" text="Blog" />
                            <a  className="text-base font-normal text-font_secondary font-roboto" href="https://help.optinly.com/" target="_blank" rel="noopener">Docs</a> 
                            <FooterLink to="/support/" text="Support" />
                            <FooterLink to="/about-us/" text="About Us" />
                            {/* <FooterLink to="/partner-program/" text="Partner Program" /> */}
                            <FooterLink to="/kerjamail/" text="Kerjamail" />
                        </div>

                    </div>

                    {/* refferal */}

                    <div className="flex flex-col items-center pt-4 space-y-5 lg:pt-0">
                        <div className="flex flex-row mx-auto space-x-8">
                            <a href="https://www.gozen.io/?utm_source=+optinly&utm_medium=referral+&utm_campaign=+optinly+to+gozen+&utm_id=+" target="_blank" rel='nofollow'>
                            <GatsbyImage image={GozenLogo} alt="Gozen Growth" className="w-20 h-24" />
                            </a>
                            <GatsbyImage image={GDPR} alt="GDPR" className="w-20 h-24 rounded-md" />
                            {/* <img src={GozenLogo} alt="Gozen Growth" className="w-20 h-24" /></a>
                            <img src={GDPR} alt="GDPR" className="w-20 h-24 rounded-md" /> */}
                        </div>

                        {/* <div onClick={() => navigate('/partner-program')} className="flex flex-col items-center w-full px-4 py-3 space-y-1 text-center transition duration-300 ease-in-out transform rounded-lg cursor-default lg:px-16 hover:scale-105" style={{backgroundColor: '#E8F1FF'}}>
                            <span className="text-xl font-semibold text-primary font-poppins">Earn Upto 30%</span>
                            <span className="text-sm font-normal text-font_secondary font-poppins">Join Our Partner Program</span>
                        </div> */}

                        <div className="flex flex-row flex-wrap items-center justify-center w-full space-x-2 ">
                            <div className="pr-2 text-base font-medium text-button_text font-poppins">Connect with us :</div>
                            <a target="_blank" href="https://www.facebook.com/getoptinly/">
                                <GatsbyImage image={facebook}  alt="facebook" className="cursor-pointer hover:opacity-40" />
                                {/* <img src={facebook} alt="facebook" className="cursor-pointer hover:opacity-40" /> */}
                                </a>
                            <a target="_blank" href="https://twitter.com/getoptinly">
                                {/* <img src={twitter} alt="twitter" className="cursor-pointer hover:opacity-40" /> */}
                                <GatsbyImage image={twitter} alt="twitter" className="cursor-pointer hover:opacity-40" />
                                </a>
                            <a target="_blank" href="https://www.linkedin.com/company/optinly/">
                                {/* <img src={linkedin} alt="linkedin" className="cursor-pointer hover:opacity-40" /> */}
                                <GatsbyImage image={linkedin}alt="linkedin" className="cursor-pointer hover:opacity-40" />
                                </a>
                        </div>

                    </div>
                </div>

                {/* Copy right */}
                <div className="flex flex-col items-center justify-between pt-3 my-5 space-y-2 md:flex-row" style={{borderTop: '0.6px solid #4B4B4B'}}>
                    <div>
                        <small className="text-base font-normal text-font_secondary font-roboto">&copy; <Link to='/' className="font-medium text-primary">Optinly</Link> Rights Reserved</small>
                    </div>
                    <div className="inline-flex items-center space-x-8">
                        <Link to="/privacy-policy/" className="text-base font-normal text-font_secondary font-roboto">Privacy policy</Link>
                        <Link to="/terms-of-service/" className="text-base font-normal text-font_secondary font-roboto">Terms of Service</Link>
                    </div>
                </div>

            </section>
        </>
    )
}

export default IntegrationFooter
