import React, { Fragment } from 'react'
import { Link } from 'gatsby'
import PropTypes from 'prop-types'
import $ from 'jquery'
import Menu from './menu'
import {MdMenu} from 'react-icons/md'
import Logo from '../images/optinlygozen.svg'
import Hi from '../images/hi.svg'
import CountDown from '../components/CountDown'

class Header extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            mobileMenu: false
        }
    }
    componentDidMount () {
        $(window).scroll(function () {
            if ($(window).scrollTop() > 10) {
                $('.navbar').addClass('past-main effect-main');
            } else {
                $('.navbar').removeClass('past-main');
            }
        })
        $(".scroll-down").click(function(event){
            event.preventDefault();
            $('html,body').animate({scrollTop:$(this.hash).offset().top-100}, 800);
        });
        $("#showDocNavBtn").on("click", function(){
            $('#docNavLinkContent').toggleClass('show');
        });
        var dropdown = document.getElementsByClassName("dropdown-btn");
        var i;

        for (i = 0; i < dropdown.length; i++) {
            dropdown[i].addEventListener("click", function() {
                this.classList.toggle("active");
                var dropdownContent = this.nextElementSibling;
                if (dropdownContent.style.display === "block") {
                    dropdownContent.style.display = "none";
                } else {
                    dropdownContent.style.display = "block";
                }
            });
        }
    }
    showMobileMenu = () =>{
        this.setState({
            mobileMenu: !this.state.mobileMenu
        })
        console.log(this.state.mobileMenu);
    }
    render () {
        return (
            <Fragment>
                {/* <div className="topbar-banner fixed-top">
                    <div className="container-fluid">
                        <p className="offer-ends">
                            <img src={Hi} className="mr-2 w-7 h-7" />
                            Try our no-code e-mail marketing software. 700+ e-mail templates that will 10X your sales : <span className='text-yellow'>First 100 signups </span> get unlimited contacts
                            <a href="https://www.gozen.io/?utm_source=+optinly&utm_medium=referral+&utm_campaign=+optinly+to+gozen+&utm_id=+" className="btn btn-secondary mx-3" target="_blank" rel='nofollow'>Start Free Trial</a>
                        </p>
                    </div>
                </div> */}
                <nav className="navbar navbar-expand-md navbar-light fixed-top-commented container-fluid">
                    <div className="container">
                        <Link className="navbar-brand" to="https://gozen.io/">
                            <img src={ Logo } alt="Optinly" /> 
                        </Link>
                        <button id="showMenuBtn" onClick={this.showMobileMenu} className="visible-xs btn btn-primary"><MdMenu/></button>
                        <Menu mobileMenu={this.state.mobileMenu} />
                    </div>
                </nav>
            </Fragment>
        )
    }
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
