import React from 'react'
import {Link} from 'gatsby'
import Menubar from '../constants/menubar';


class MenuCard extends React.Component {

    static menuCallout() {
        return Menubar.map((card) => {
             const cardClass = `nav-link `;
            return (
                <li className={"nav-item " + (card.DROPDOWN_LINKS ? 'dropdown' : '')}>
                    { !card.DROPDOWN_LINKS &&
                        <Link activeClassName="active" to={card.LINK} className={cardClass} target={card.TARGET}>{card.TITLE}</Link>
                    }
                    { card.DROPDOWN_LINKS &&
                        <>
                            <Link className={"nav-link " + (card.DROPDOWN_LINKS ? 'dropdown-toggle' : '')} to={card.LINK} id={card.DROPDOWN_TARGET} role="button"
                               data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                {card.TITLE}
                            </Link>
                            <div className={ (card.ISMEGAMENU ? 'dropdown-menu is-mega-menu' : 'dropdown-menu')} aria-labelledby={card.DROPDOWN_TARGET}>
                                { !card.ISMEGAMENU &&
                                    card.DROPDOWN_LINKS.map((link) => {
                                        return (
                                            <a className="dropdown-item" href={link.LINK} target={link.TARGET}>{link.TITLE} {<img className="img img-responsive w-50 px-2" style={{verticalAlign: 'middle'}} src={link.ICON}/>}</a>
                                        )
                                    })
                                }

                                { card.ISMEGAMENU &&
                                    <ul className="productsGroupPrimary">
                                        {card.DROPDOWN_LINKS.map(link => {
                                            if (!link.MENUTITLE && !link.SUB_DROPDOWN_MENU) {
                                                return (
                                                    <li key={link.TITLE}>
                                                        <Link
                                                        activeClassName="active"
                                                        className="linkContainer"
                                                        to={link.LINK}
                                                        >
                                                        <div className="productLinkContent">
                                                            <h3 className="linkTitle">
                                                            {link.TITLE}
                                                            </h3>
                                                        </div>
                                                        </Link>
                                                    </li>
                                                )
                                            } else {
                                                return (
                                                    <li className="submenu" key={link.MENUTITLE}>
                                                        <h6 className="menu-title">{link.MENUTITLE}</h6>
                                                        {link.SUB_DROPDOWN_MENU_LINKS && (
                                                            <ul>
                                                            {link.SUB_DROPDOWN_MENU_LINKS.map(sublink => {
                                                                return (
                                                                <li key={sublink.TITLE}>
                                                                    <Link
                                                                    activeClassName="active"
                                                                    className="linkContainer"
                                                                    to={sublink.LINK}
                                                                    >
                                                                    {/* <img src={sublink.ICON} alt="" /> */}
                                                                    <div className="productLinkContent">
                                                                        <h3 className="linkTitle">
                                                                        {sublink.TITLE}
                                                                        </h3>
                                                                        {/* <p className="linkSub">
                                                                        {sublink.DESC}
                                                                        </p> */}
                                                                    </div>
                                                                    </Link>
                                                                </li>
                                                                )
                                                            })}
                                                            </ul>
                                                        )}
                                                    </li>
                                                )
                                            }
                                            })}
                                    </ul>
                                }

                            </div>
                        </>
                    }
                </li>
            );
        });
    }

    render() {
        return (
         <ul className="navbar-nav ms-auto navbar-center">
            {MenuCard.menuCallout()}
        </ul>
        )
    }
}

export default MenuCard
