import React from 'react'
import MenuCard from './menucontent'

const Menu = (props) => (
    <div className={`collapse navbar-collapse  ${props.mobileMenu ? 'show' : ''}`} id="navbarSupportedContent">
        <MenuCard />
         <ul className="navbar-nav ms-auto navbar-right">
            <li className="nav-item">
                <a href="https://app.optinly.com/" target="_blank" className="btn-cta nav-link">Login</a>
            </li>
            <li className="nav-item">
                <a href="https://app.optinly.com/" target="_blank" className="btn-cta btn-secondary nav-link">Sign up</a>
            </li>
        </ul>
    </div>
)

export default Menu;