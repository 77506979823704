import React from 'react'
import PropTypes from 'prop-types'
import {StaticQuery, graphql} from 'gatsby'
import Header from './header'
import Menu from './menu'
import Footer from './footer'
import 'bootstrap/dist/css/bootstrap.min.css'
import './layout.scss'
import ScrollButton from './ScrollButton'
import IntegrationFooter from './Integrations/IntegrationFooter'


if (typeof window !== 'undefined') {
    window.jQuery = window.$ = require('jquery');
    require('bootstrap');
}

const Layout = ({children, className, location, crumbLabel, isIntegrationPage=false}) => (
    <StaticQuery
        query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
        render={data => (
            <>
                <div className={"wrapper " + (className ? className : '')}>
                    <Header siteTitle={data.site.siteMetadata.title}/>
                    <Menu />
                    <div id="main" className="main">
                        {children}
                        {/* {isIntegrationPage ? <IntegrationFooter /> : <Footer/>} */}
                        <IntegrationFooter /> 
                    </div>
                </div>
              <ScrollButton />
              
            </>
        )}
    />
)

Layout.propTypes = {
    children: PropTypes.node.isRequired,
}

export default Layout
