import { Link } from 'gatsby'
import React from 'react'

const FooterLink = ({to, text}) => {
    return (
        <>
            <Link to={to} className="text-base text-font_secondary font-normal font-roboto">{text}</Link>
        </>
    )
}

export default FooterLink
